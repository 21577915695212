import { Pipe, PipeTransform } from '@angular/core';
import { isMobile, isTablet } from './mobile-detect';

@Pipe({
  name: 'isMobileDevice',
})
export class IsMobileDevicePipe implements PipeTransform {
  transform(value: unknown): boolean {
    return isMobile || isTablet;
  }
}
