import { Component } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { CitydealsFormGroup } from '@partneradmin/common';
import { NgxFormErrorConfig } from '@partneradmin/feature/ngx-form-error';
import { CustomValidators } from 'ngx-custom-validators';
import { MessageService } from 'primeng/api';
import { ChangePasswordAction } from '../../state/action/change-password.action';
import { LoginAction } from '../../state/action/login.action';
import { UserState } from '@partneradmin/core/user';

@UntilDestroy()
@Component({
  selector: 'partneradmin-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  providers: [MessageService],
})
export class SetPasswordComponent {
  readonly newPassword1Control = new FormControl(null, Validators.required);
  readonly newPassword2Control = new FormControl(
    null,
    CustomValidators.equalTo(this.newPassword1Control as any) as unknown as ValidatorFn
  );
  readonly form = new CitydealsFormGroup({
    newPassword1: this.newPassword1Control,
    newPassword2: this.newPassword2Control,
  });
  showPassword = false;

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private errorFormConfig: NgxFormErrorConfig
  ) {
    this.watchSuccessSetPassword();

    this.errorFormConfig.updateMessages({
      equalTo: this.translocoService.translate('USER.SET_PASSWORD.FORM.CONTROL.NEW_PASSWORD2.ERROR.EQUAL_TO'),
    });
  }

  watchSuccessSetPassword() {
    this.actions$.pipe(ofActionSuccessful(ChangePasswordAction), untilDestroyed(this)).subscribe(() => {
      this.messageService.add({
        key: 'set-password',
        severity: 'success',
        summary: this.translocoService.translate('USER.SET_PASSWORD.FORM.SUCCESS_NOTIFI'),
      });
      this.store
        .dispatch(
          new LoginAction(
            this.store.selectSnapshot(UserState.hasPasswordEmail) ?? '',
            this.form.getRawValue().newPassword1
          )
        )
        .subscribe(() => this.router.navigate(['/']));
    });
  }

  onSubmit() {
    this.form.submitted = true;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const { newPassword1, newPassword2 } = this.form.value;
      this.form.disable();
      this.store.dispatch(new ChangePasswordAction(null, newPassword1, newPassword2)).subscribe({
        error: error => {
          this.form.enable();
          this.messageService.add({
            key: 'set-password',
            severity: 'error',
            summary: this.translocoService.translate('USER.SET_PASSWORD.FORM.REMOTE_ERROR_NOTIFI_TITLE'),
            detail: error.error.message,
          });
        },
      });
    }
  }
}
