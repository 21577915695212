<partneradmin-unauth-wrapper titleTranslateKey="USER.SET_PASSWORD.TITLE">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *transloco="let t; read: 'USER.SET_PASSWORD.FORM'">
    <div class="col-12 mb-2 lg:mb-0">
      <div class="p-inputgroup">
        <input
          class="w-full"
          [type]="showPassword ? 'text' : 'password'"
          [placeholder]="t('CONTROL.NEW_PASSWORD1')"
          pInputText
          [formControl]="newPassword1Control"
        />
        <span class="p-inputgroup-addon p-0"
          ><button
            pButton
            pRipple
            type="button"
            [icon]="'pi ' + (showPassword ? 'pi-eye-slash' : 'pi-eye')"
            class="p-button-sm p-button-text"
            (click)="showPassword = !showPassword"
            tabindex="-1"
          ></button
        ></span>
      </div>
      <ngx-form-error [control]="newPassword1Control"></ngx-form-error>
    </div>
    <div class="col-12 mb-2 lg:mb-0">
      <div class="p-inputgroup">
        <input
          class="w-full"
          [type]="showPassword ? 'text' : 'password'"
          [placeholder]="t('CONTROL.NEW_PASSWORD2.PLACEHOLDER')"
          pInputText
          [formControl]="newPassword2Control"
        />
        <span class="p-inputgroup-addon p-0"
          ><button
            pButton
            pRipple
            type="button"
            [icon]="'pi ' + (showPassword ? 'pi-eye-slash' : 'pi-eye')"
            class="p-button-sm p-button-text"
            (click)="showPassword = !showPassword"
            tabindex="-1"
          ></button
        ></span>
      </div>
      <ngx-form-error [control]="newPassword2Control"></ngx-form-error>
    </div>
    <div class="col-12 mt-2 text-right"
      ><button type="submit" pButton class="p-button-rounded" [label]="t('SUBMIT')"></button
    ></div>
  </form>
</partneradmin-unauth-wrapper>

<p-toast key="set-password"></p-toast>
