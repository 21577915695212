import { NgModule } from '@angular/core';
import { NgxFormErrorComponent } from './ngx-form-error.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [NgxFormErrorComponent],
  imports: [CommonModule],
  exports: [NgxFormErrorComponent],
})
export class NgxFormErrorModule {}
