import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { EntityListResponse } from './entity-list.response';
import { isNil } from '@partneradmin/typeguard';
import { environment } from '@partneradmin/env';
import { format } from 'date-fns';

/**
 * grid eseten az entitas service-nek meg kell ezt az osztaly valositania
 */
export abstract class AbstractEntityService<E extends any, EL extends EntityListResponse<E>> {
  protected constructor(
    protected endpointName: string,
    protected _defaultSort: Extract<keyof E, string>,
    protected http: HttpClient
  ) {}

  get defaultSort(): string {
    return this._defaultSort;
  }

  get(id: number): Observable<E> {
    // return this.http.get<E>();
    return of({ id: 1 } as E);
  }

  getAll({ first, rows, filters, sortField, sortOrder }: LazyLoadEvent): Observable<EL> {
    const params: Partial<{
      [filterKeys: string]: string;
      page: string;
      pageSize: string;
      sort_by: Extract<keyof E, string>;
      sort_dir: '1' | '0';
    }> = {
      page: `${Math.ceil((first ?? 1) / (rows ?? 0)) + 1}`,
      pageSize: `${rows}`,
    };
    if (!isNil(sortField)) {
      params.sort_by = sortField as never;
      params.sort_dir = `${sortOrder === -1 ? 0 : 1}`;
    }
    this.setFilters(filters, params);
    return this.http.get<EL>(`${environment.backendUrl}/${this.endpointName}`, { params: params as never });
  }

  private setFilters(
    filters?: { [p: string]: FilterMetadata },
    params?: Partial<{
      [p: string]: string;
    }>
  ) {
    if (!isNil(filters) && !isNil(params)) {
      const filterEntries = Object.entries(filters);
      if (filterEntries.length > 0) {
        //  TODO extra eseteket valahogy kezelni, pl: datum, ha kell blacklist-vel :(
        filterEntries.forEach(entry => {
          if (entry[1].matchMode === 'dateRangeFilter') {
            if (Array.isArray(entry[1].value)) {
              let startKey = entry[0];
              let endKey = entry[0];
              if (entry.indexOf('__') > -1) {
                const splittedKey = entry[0].split('__');
                startKey = splittedKey[0];
                endKey = splittedKey[1];
              }
              params[`${startKey}_from_date`] = format(entry[1].value[0], 'yyyy-MM-dd');
              params[`${endKey}_until_date`] = format(entry[1].value[1], 'yyyy-MM-dd');
            }
          } else if (!isNil(entry[1].value)) {
            if (entry[1].matchMode === 'select') {
              params[entry[0]] = entry[1].value.key;
            } else {
              params[entry[0]] = entry[1].value;
            }
          }
        });
      }

      const removeKeys: string[] = [];
      Object.entries(params).forEach(p => {
        if (p[1] === null) {
          removeKeys.push(p[0]);
        }
      });
      removeKeys.forEach(rk => delete params[rk]);
    }
  }

  createOrModify(entity: E): Observable<E> {
    // TODO ha kulon kell POST es PATCH akkor azt meg le kell kezelni
    // return this.http.post<E>(`${}/`, entity);
    return of({ id: 1 } as E);
  }

  remove(id: number): Observable<void> {
    // return this.http.delete<void>(`${}/${id}/`);
    return of();
  }

  exportToExcel(filters: { [s: string]: FilterMetadata }) {
    const params = {};
    this.setFilters(filters, params);
    return this.http.get<HttpResponse<Blob>>(`${environment.backendUrl}/${this.endpointName}/export`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params,
    });
  }

  abstract getEntityId(model: Partial<E>): number | null;
}
