import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CitydealsFormGroup, IsMobileDevicePipe } from '@partneradmin/common';
import { TranslocoService } from '@ngneat/transloco';
import { FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from '@partneradmin/env';

@Component({
  selector: 'partneradmin-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [MessageService],
})
export class ForgotPasswordComponent {
  readonly emailControl = new FormControl(null, Validators.compose([Validators.required, Validators.email]));
  readonly form = new CitydealsFormGroup({
    email: this.emailControl,
  });

  #router: Router;
  #httpClient: HttpClient;
  #messageService: MessageService;
  #translocoService: TranslocoService;

  readonly today = new Date();
  readonly isMobileOrTabletDevice = new IsMobileDevicePipe().transform(undefined);

  constructor(
    router: Router,
    http: HttpClient,
    messageService: MessageService,
    translocoService: TranslocoService
  ) {
    this.#router = router;
    this.#httpClient = http;
    this.#messageService = messageService;
    this.#translocoService = translocoService;
  }

  onSubmit() {
    this.form.submitted = true;
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.form.disable();
      this.#httpClient.post(`${environment.backendUrl}/forgot-password/partner/`, this.form.value).subscribe({
        next: (response: any) => {
          this.form.enable();
          this.#messageService.add({
            severity: 'success',
            key: 'forgot-password-error',
            summary: this.#translocoService.translate('USER.FORGOT_PASSWORD.FORM.SUCCESS'),
          });
        },
        error: error => {
          this.form.enable();
          this.#messageService.add({
            severity: 'error',
            key: 'forgot-password-error',
            summary: this.#translocoService.translate('USER.FORGOT_PASSWORD.FORM.REMOTE_ERROR_NOTIFI_TITLE'),
            detail: error.error.message,
          });
        },
      });
    }
  }
}
