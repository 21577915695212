import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, AuthTokenGuard, NotAuthGuard } from '@partneradmin/core/user';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        //{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
        { path: '', pathMatch: 'full', redirectTo: 'voucher-check' },
        {
          path: 'login',
          loadChildren: () => import('@partneradmin/core/user').then(m => m.LoginModule),
          canActivate: [NotAuthGuard],
        },
        {
          path: 'forgot-password',
          loadChildren: () => import('@partneradmin/core/user').then(m => m.ForgotPasswordModule),
          canActivate: [NotAuthGuard],
        },
        {
          path: '',
          loadChildren: () => import('@partneradmin/core/app-layout').then(m => m.CoreAppLayoutModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'user/set-password',
          loadChildren: () => import('@partneradmin/core/user').then(m => m.SetPasswordModule),
          canActivate: [AuthTokenGuard, AuthGuard],
        },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        useHash: false,
        enableTracing: false,
        scrollPositionRestoration: 'enabled',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
