import { Component, OnInit } from '@angular/core';
import { CitydealsFormGroup } from '@partneradmin/common';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { PartnerDetailService } from './details.service';
import { MessageService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { PartnerDetailEntity, PartnerDetailRedeemablePlaceEntity } from './entity/partner-details.entity';

@Component({
  selector: 'partneradmin-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [PartnerDetailService, MessageService],
})
export class DetailsComponent implements OnInit {
  readonly form = new CitydealsFormGroup({});

  #store: Store;
  #router: Router;
  #messageService: MessageService;
  #translocoService: TranslocoService;
  #partnerDetailService: PartnerDetailService;
  selectedRedeemablePlace!: PartnerDetailRedeemablePlaceEntity | null;

  partnerDetailEntity!: PartnerDetailEntity | null;

  constructor(
    store: Store,
    router: Router,
    messageService: MessageService,
    translocoService: TranslocoService,
    partnerDetailService: PartnerDetailService
  ) {
    this.#store = store;
    this.#router = router;
    this.#messageService = messageService;
    this.#translocoService = translocoService;
    this.#partnerDetailService = partnerDetailService;
  }

  ngOnInit(): void {
    this.getPartnerDetails();
  }

  getPartnerDetails() {
    this.#partnerDetailService.getPartnerDetail().subscribe({
      next: (response: PartnerDetailEntity) => {
        this.partnerDetailEntity = response;
        this.partnerDetailEntity?.redeemable_pace.sort(
          (item1, item2) => item1.place_name.localeCompare(item2.place_name)
        )
        this.selectedRedeemablePlace = this.partnerDetailEntity?.redeemable_pace[0];

      },
      error: error => {
        this.#messageService.add({
          key: 'login-error',
          severity: 'error',
          summary: this.#translocoService.translate('PAGE.VOUCHER_CHECK.FORM.ERROR'),
          detail: error.error.message,
        });
      },
    });
  }
}
