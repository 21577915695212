import { Pipe, PipeTransform } from '@angular/core';
import { isMobile } from './mobile-detect';

@Pipe({
  name: 'isMobile',
})
export class IsMobilePipe implements PipeTransform {
  transform(value: unknown) {
    return isMobile;
  }
}
