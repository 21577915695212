import { isNil } from '@partneradmin/typeguard';

/* eslint-disable */

export function deepCopy<T>(obj: T): T {
  let cp: T;
  if (isNil(obj) || (obj as any).__disable_deep_copy__ !== undefined || (obj as any).__disable_deep_copy__ === false) {
    cp = obj;
  } else if (obj instanceof Date) {
    cp = new Date((obj as any).getTime()) as any;
  } else if (Array.isArray(obj)) {
    cp = [] as any;
    (obj as any[]).forEach(v => {
      (cp as any).push(v);
    });
    cp = (cp as any).map((n: any) => deepCopy<any>(n));
  } else {
    if (typeof obj === 'object' && obj !== {}) {
      cp = { ...(obj as Object) } as T;
      Object.keys(cp).forEach(k => {
        (cp as any)[k] = deepCopy<any>((cp as any)[k]);
      });
    } else {
      cp = obj;
    }
  }
  return cp;
}
