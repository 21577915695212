import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SetPasswordComponent } from './set-password.component';
import { SetPasswordLayoutGuard } from './set-password-layout.guard';

const routes: Routes = [
  {
    path: '',
    component: SetPasswordComponent,
    canActivate: [SetPasswordLayoutGuard],
    canDeactivate: [SetPasswordLayoutGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [SetPasswordLayoutGuard],
})
export class SetPasswordRoutingModule {}
