import { InjectionToken, Provider, Type } from '@angular/core';
import { AbstractEntityService } from './abstract-entity.service';

/**
 * entitas service-t jeloljuk meg a DI-ben
 */
export const ENTITY_SERVICE_TOKEN = new InjectionToken<
  // eslint-disable-next-line
  AbstractEntityService<any, any>
>('ENTITY_SERVICE_TOKEN');

export function provideEntityService(entityClass: Type<AbstractEntityService<any, any>>): Provider {
  return {
    provide: ENTITY_SERVICE_TOKEN,
    useClass: entityClass,
  };
}
