import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  backendUrl: 'https://api.dev.foxydeals.at/api',
  backendStorageBaseUrl: 'https://dev.foxydeals.at',
  backendBucketStorageBaseUrl: 'https://storage.googleapis.com/foxydeals-images/public',
  extraPlugins: [],
  frontpageSiteUrl: 'https://dev.foxydeals.at',
};
