import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { PartnerInfo, UserStateModel } from './model/user-state.model';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { LogoutAction } from './action/logout.action';
import { HttpClient } from '@angular/common/http';
import { LoginAction } from './action/login.action';
import { deepCopy } from '@partneradmin/common';
import { environment } from '@partneradmin/env';
import { mergeMap, tap } from 'rxjs';
import { ChangePasswordAction } from './action/change-password.action';
import { LoginWithTokenAction } from './action/login-with-token.action';
import { ReloadPartnerInfoAction } from './action/reload-partner-info.action';
import { UserEntity } from './entity/user.entity';
import { isBoolean, isNil } from '@partneradmin/typeguard';

export const USER_STATE_KEY = 'user';

const defaultState: UserStateModel = { token: null, user: null, partner: null };

@State<UserStateModel>({
  name: USER_STATE_KEY,
  defaults: {
    ...defaultState,
  },
})
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(private http: HttpClient) {}

  @Selector()
  @ImmutableSelector()
  static isLoggedIn(state: UserStateModel): boolean {
    return state.token !== null;
  }

  @Selector()
  @ImmutableSelector()
  static currentUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  @ImmutableSelector()
  static partner(state: UserStateModel): PartnerInfo | null {
    return state.partner;
  }

  @Selector()
  @ImmutableSelector()
  static token(state: UserStateModel): string | null {
    return state.token;
  }

  @Selector()
  @ImmutableSelector()
  static hasPassword(state: UserStateModel): boolean {
    return isBoolean(state.hasPassword) ? state.hasPassword : false;
  }

  @Selector()
  @ImmutableSelector()
  static hasPasswordEmail(state: UserStateModel): string | undefined {
    return state.hasPasswordEmail;
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
    const state = ctx?.getState();
    if (!isNil(state) && UserState.isLoggedIn(state)) {
      ctx?.dispatch(new ReloadPartnerInfoAction());
    }
  }

  @Action(LoginAction)
  @ImmutableContext()
  login({ setState }: StateContext<UserStateModel>, { email, password }: LoginAction) {
    return this.http
      .post<{ token: string; user: any; partner: any /*TODO meg nem kaptunk rendes leirot hozza!*/ }>(
        `${environment.backendUrl}/loginpartner`,
        { email, password }
      )
      .pipe(
        tap(({ token, user, partner }) => {
          setState((state: UserStateModel) => {
            const loggedPartnerInfo: PartnerInfo = {
              id: partner.id,
              company_name: partner.companyName,
            };

            state.user = user;
            state.token = token;
            state.partner = loggedPartnerInfo;

            return state;
          });
        }),
        mergeMap(() => this.http.get<UserEntity>(`${environment.backendUrl}/partner/auth`)),
        tap(({ has_password }: UserEntity) => {
          setState((state: UserStateModel) => {
            state.hasPassword = has_password;
            return state;
          });
        })
      );
  }

  @Action(ChangePasswordAction)
  @ImmutableContext()
  changePassword(
    { setState }: StateContext<UserStateModel>,
    { oldPassword, newPassword, newPassword2 }: ChangePasswordAction
  ) {
    const body: Record<string, string> = {
      password: newPassword,
      password_confirmation: newPassword2,
    };

    if (!isNil(oldPassword)) {
      body['old_password'] = oldPassword;
    }

    return this.http.patch<void>(`${environment.backendUrl}/partner/auth/password`, body).pipe(
      tap(() => {
        setState((state: UserStateModel) => {
          state.hasPassword = true;
          return state;
        });
      })
    );
  }

  @Action(LogoutAction)
  @ImmutableContext()
  logout({ setState }: StateContext<UserStateModel>) {
    setState((state: UserStateModel) => {
      return deepCopy(defaultState);
    });
  }

  @Action(LoginWithTokenAction)
  @ImmutableContext()
  loginWithToken({ setState }: StateContext<UserStateModel>, { token }: LoginWithTokenAction) {
    setState((state: UserStateModel) => {
      state.token = token;
      return state;
    });
  }

  @Action(ReloadPartnerInfoAction)
  @ImmutableContext()
  reloadUserData({ setState }: StateContext<UserStateModel>) {
    return this.http.get<UserEntity>(`${environment.backendUrl}/partner/auth`).pipe(
      tap((user: UserEntity) => {
        setState((state: UserStateModel) => {
          const loggedPartnerInfo: PartnerInfo = {
            id: user.id,
            company_name: user.company_name,
          };

          state.partner = loggedPartnerInfo;
          state.hasPassword = user.has_password;
          state.hasPasswordEmail = user.company_email;
          return state;
        });
      })
    );
  }
}
