import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { NgxFormErrorModule } from '@partneradmin/feature/ngx-form-error';
import { TranslocoModule } from '@ngneat/transloco';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { MobileDetectModule } from '@partneradmin/common';
import { UnauthWrapperModule } from '../unauth-wrapper/unauth-wrapper.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    NgxFormErrorModule,
    TranslocoModule,
    ToastModule,
    CheckboxModule,
    MobileDetectModule,
    UnauthWrapperModule,
  ],
})
export class LoginModule {}
