import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangePasswordRoutingModule } from './change-password-routing.module';
import { ChangePasswordComponent } from './login/change-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TranslocoModule } from '@ngneat/transloco';
import { ToastModule } from 'primeng/toast';
import { NgxFormErrorModule } from '@partneradmin/feature/ngx-form-error';

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [
    CommonModule,
    ChangePasswordRoutingModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    NgxFormErrorModule,
    TranslocoModule,
    ToastModule,
  ],
})
export class ChangePasswordModule {}
