import { Injectable } from '@angular/core';
import { AbstractEntityService, EntityListResponse } from '@partneradmin/core/entity';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@partneradmin/env';
import {PartnerDetailEntity} from "./entity/partner-details.entity";

@Injectable()
export class PartnerDetailService extends AbstractEntityService<
  PartnerDetailEntity,
  EntityListResponse<PartnerDetailEntity>
  > {
  constructor(http: HttpClient) {
    super('partner/auth', 'id', http);
  }

  getEntityId(model: Partial<PartnerDetailEntity>): number | null {
    return null;
  }

  getPartnerDetail(): Observable<PartnerDetailEntity> {
    return this.http.get<PartnerDetailEntity>(
      `${environment.backendUrl}/${this.endpointName}/`
    );
  }
}
