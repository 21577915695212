<div
  class="wrapper"
  [ngClass]="{ 'p-5 align-items-center flex-column justify-content-center': isMobileOrTabletDevice }"
>
  <img class="mb-5 mobile-logo" *ngIf="isMobileOrTabletDevice" src="/assets/images/foxydeals_logo_feher.png" />
  <div class="left-side" *ngIf="('' | isMobileDevice) === false">
    <img class="mb-5 desktop-logo" src="/assets/images/foxydeals_logo.png" />
    <img style="width: 100%; height: 100%" src="/assets/images/login_image.jpg" />
  </div>
  <div class="right-side" [ngClass]="{ 'card p-3': isMobileOrTabletDevice }">
    <h1>{{ 'USER.FORGOT_PASSWORD.TITLE' | transloco }}</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *transloco="let t; read: 'USER.FORGOT_PASSWORD.FORM'">
      <div class="col-12 mb-2 lg:mb-0">
        <input class="w-full" type="email" pInputText [placeholder]="t('CONTROL.EMAIL')" [formControl]="emailControl" />
        <ngx-form-error [control]="emailControl"></ngx-form-error>
      </div>
      <div class="flex" style="justify-content: space-between; align-items: center; padding-top: 10px; gap:10px;">
        <button
          type="submit"
          pButton
          class="p-button-rounded"
          [ngClass]="{ 'w-full': isMobileOrTabletDevice }"
          [label]="t('SUBMIT')"
        >
        </button>
        <div>
          <a routerLink="/login" class="link">{{ t('LINK') }}</a>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="layout-footer flex align-items-center p-1 pr-4 pl-4 shadow-2 w-100" *ngIf="isMobileOrTabletDevice">
  <img class="footer-logo" src="/assets/images/foxydeals_logo.png" style="height: 1.8rem; position:relative; top:-2px;" />
  <p class="copyright m-auto">© {{ today | date: 'yyyy' }} FOXYDEALS</p>
  <ng-container *ngIf="!('' | isMobile)">
    <a class="mr-5" href="https://foxydeals.at">Zu Foxydeals wechseln</a>
    <a class="mr-5" href="https://ezhovamutasson.com">Untertützung</a>
  </ng-container>
</div>

<p-toast key="forgot-password-error"></p-toast>
