import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AppComponent } from './app.component';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@partneradmin/env';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthInterceptor, USER_STATE_KEY, UserState } from '@partneradmin/core/user';
import localeDe from '@angular/common/locales/de';
import { NgxFormErrorConfig, NgxFormErrorModule } from '@partneradmin/feature/ngx-form-error';
import { PrimeNGConfig } from 'primeng/api';

registerLocaleData(localeDe);

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, interactionPlugin]);

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    NgxsModule.forRoot([UserState], {
      developmentMode: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({ key: [USER_STATE_KEY] }),
    NgxFormErrorModule,
    ...environment.extraPlugins,
    // NgxsReduxDevtoolsPluginModule.forRoot({}),
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translocoService: TranslocoService, errorFormConfig: NgxFormErrorConfig, config: PrimeNGConfig) {
    /**
     * FIX: valamiert ng13-ban nem mukodik meg az auto betoltes, ezert mi indukaljuk
     */
    translocoService.load('hu').subscribe(jsonFileContent => {
      const hu = translocoService.getTranslation()?.get('hu');
      errorFormConfig.updateMessages({
        /**
         * Lehetseges default kulcsok:
         * https://github.com/mmmichl/ngx-form-error/blob/master/projects/ngx-form-error/src/lib/ngx-form-error.config.ts#L14
         * egyedi kulcs eseten csak fel kell venni
         */
        required: hu?.['FORM.VALIDATOR.REQUIRED'],
        email: hu?.['FORM.VALIDATOR.EMAIL'],
      });

      config.setTranslation(jsonFileContent['PRIMENG']);
    });
  }
}
