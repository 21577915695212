import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { NgxFormErrorModule } from '@partneradmin/feature/ngx-form-error';
import { TranslocoModule } from '@ngneat/transloco';
import { ToastModule } from 'primeng/toast';
import { MobileDetectModule } from '@partneradmin/common';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    ForgotPasswordRoutingModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    ToastModule,
    NgxFormErrorModule,
    TranslocoModule,
    MobileDetectModule,
  ],
})
export class ForgotPasswordModule {}
