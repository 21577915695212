import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, take } from 'rxjs';
import { UserState } from './state/user.state';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private store: Store) {}

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(route.queryParams);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.check(route.queryParams);
  }

  private check(queryParams: any) {
    return this.store.select(UserState.isLoggedIn).pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          return this.router.createUrlTree(['/login'], {queryParams:queryParams});
        }
        return true;
      })
    );
  }
}
