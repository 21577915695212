import { CanActivate, CanDeactivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, switchMap, take } from 'rxjs';
import { UserState } from '../../state/user.state';
import { LogoutAction } from '../../state/action/logout.action';

@Injectable()
export class SetPasswordLayoutGuard implements CanActivate, CanDeactivate<unknown> {
  constructor(private router: Router, private store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(UserState.hasPassword).pipe(
      take(1),
      switchMap(hasPassword => {
        if (hasPassword === false) {
          return of(true);
        } else {
          // ha mar van jelszava es ugy jott be token-vel
          this.store.dispatch(new LogoutAction());
          return of(this.router.createUrlTree(['/']));
        }
      })
    );
  }

  canDeactivate(): Observable<boolean> {
    return this.store.select(UserState.hasPassword).pipe(take(1));
  }
}
