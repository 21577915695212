import { FormGroup } from '@angular/forms';

export class CitydealsFormGroup extends FormGroup {
  submitted = false;

  override reset(value?: unknown, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.submitted = false;
    super.reset(value, options);
  }
}
