import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsNumericPipe } from './is-numeric.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsNumericPipe],
  exports: [IsNumericPipe],
})
export class IsNumericModule {}
