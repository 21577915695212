<partneradmin-unauth-wrapper titleTranslateKey="USER.LOGIN.TITLE"
  ><form [formGroup]="form" (ngSubmit)="onSubmit()" *transloco="let t; read: 'USER.LOGIN.FORM'">
    <div class="col-12 mb-2 lg:mb-0">
      <input class="w-full" type="email" pInputText [placeholder]="t('CONTROL.EMAIL')" [formControl]="emailControl" />
      <ngx-form-error [control]="emailControl"></ngx-form-error>
    </div>
    <div class="col-12 mb-2 lg:mb-0">
      <div class="p-inputgroup">
        <input
          class="w-full"
          [type]="showPassword ? 'text' : 'password'"
          [placeholder]="t('CONTROL.PASSWORD')"
          pInputText
          [formControl]="passwordControl"
        />
        <span class="p-inputgroup-addon p-0"
          ><button
            pButton
            pRipple
            type="button"
            [icon]="'pi ' + (showPassword ? 'pi-eye-slash' : 'pi-eye')"
            class="p-button-sm p-button-text"
            (click)="showPassword = !showPassword"
            tabindex="-1"
          ></button
        ></span>
      </div>
      <ngx-form-error [control]="passwordControl"></ngx-form-error>
    </div>
    <div class="col-12 mb-2">
      <div class="flex flex-space-between">
        <div class="field-checkbox">
          <p-checkbox [binary]="true" inputId="binary" id="xxx"></p-checkbox>
          <label for="xxx">{{ t('CONTROL.BEJELENTKEZVE_CHECKBOX') }}</label>
        </div>
        <div>
          <a routerLink="/forgot-password" class="link">{{ t('LINK') }}</a>
        </div>
      </div>
    </div>
    <div
      ><button
        type="submit"
        pButton
        class="p-button-rounded"
        [ngClass]="{ 'w-full': isMobileOrTabletDevice }"
        [label]="t('SUBMIT')"
      ></button
    ></div>
  </form>
</partneradmin-unauth-wrapper>

<p-toast key="login-error"></p-toast>
