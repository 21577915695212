<div *ngIf="partnerDetailEntity; else loader">
  <div class="partner-form"  *transloco="let t; read: 'PAGE.PARTNER_DETAILS.FORM'">
    <h2>{{t('TITLE')}}</h2>
    <div class="p-fluid grid formgrid">

      <div class="field col-12 md:col-12">
        <h5 class="partner-form-h5">
          {{t('HEADER_SECTION_1_TEXT')}}
        </h5>
      </div>

      <div class="field col-12 md:col-12">
        <label class="partner-form-label" for="invoiceName">
          {{t('INVOICE_NAME_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="invoiceName"
          value="{{partnerDetailEntity?.company_name}}"
        />
      </div>

      <div class="field col-12 md:col-2">
        <label class="partner-form-label" for="invoiceZipcode">
          {{t('INVOICE_ZIPCODE_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="invoiceZipcode"
          value="{{partnerDetailEntity?.billing_zipcode}}"
        />
      </div>
      <div class="field col-12 md:col-4">
        <label class="partner-form-label" for="invoiceSettlement">
          {{t('INVOICE_SETTLEMENT_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="invoiceSettlement"
          value="{{partnerDetailEntity?.settlement}}"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label class="partner-form-label" for="invoiceStreet">
          {{t('INVOICE_STREET_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="invoiceStreet"
          value="{{partnerDetailEntity?.billing_address_name}} {{partnerDetailEntity?.billing_address_num}}"
        />
      </div>

      <div class="field col-12 md:col-6">
        <label class="partner-form-label" for="companyRegister">
          {{t('COMPANY_REGISTER_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="companyRegister"
          value="{{partnerDetailEntity?.company_number}}"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label class="partner-form-label" for="taxNumber">
          {{t('COMPANY_TAX_NUMBER_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="taxNumber"
          value="{{partnerDetailEntity?.tax_number}}"
        />
      </div>

      <div class="field col-12 md:col-6">
        <label class="partner-form-label" for="bankName">
          {{t('BANK_NAME_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="bankName"
          value="{{partnerDetailEntity?.bank_name}}"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label class="partner-form-label" for="bankAccountNumber">
          {{t('BANK_ACCOUNT_NUMBER_LABEL')}}
        </label>
        <input
          type="text"
          pInputText
          readonly
          id="bankAccountNumber"
          value="{{partnerDetailEntity?.invoice_number}}"
        />
      </div>

      <div class="field col-12 md:col-12">
        <h5 class="partner-form-h5">
          {{t('HEADER_SECTION_2_TEXT')}}
        </h5>
      </div>

      <div class="field col-12 md:col-12">
        <p-dropdown
          [showClear]="true"
          optionLabel="place_name"
          placeholder="Beváltóhely kiválasztása"
          [(ngModel)]="selectedRedeemablePlace"
          [options]="partnerDetailEntity!.redeemable_pace">
        </p-dropdown>
      </div>

      <ng-container *ngIf="selectedRedeemablePlace">
        <div class="field col-12 md:col-12">
          <label class="partner-form-label" for="place">
            {{t('REDEEMABLE_PACE_TEXT')}}
          </label>
          <input
            type="text"
            pInputText
            readonly
            id="place"
            value="{{ selectedRedeemablePlace?.place_name }}"
          />
        </div>

        <div class="field col-12 md:col-2">
          <label class="partner-form-label" for="postcode">
            {{t('INVOICE_ZIPCODE_LABEL')}}
          </label>
          <input
            type="text"
            pInputText
            readonly
            id="postcode"
            value="{{ selectedRedeemablePlace?.zipcode }}"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label class="partner-form-label" for="city">
            {{t('INVOICE_SETTLEMENT_LABEL')}}
          </label>
          <input
            type="text"
            pInputText
            readonly
            id="city"
            value="{{ selectedRedeemablePlace?.city }}"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label class="partner-form-label" for="street">
            {{t('INVOICE_STREET_LABEL')}}
          </label>
          <input
            type="text"
            pInputText
            readonly
            id="street"
            value="{{ selectedRedeemablePlace?.address_name }} {{ selectedRedeemablePlace?.address_num }}"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label class="partner-form-label" for="phone">
            {{t('REDEEMABLE_PHONE_LABEL')}}
          </label>
          <input
            type="text"
            pInputText
            readonly
            id="phone"
            value="{{ selectedRedeemablePlace?.tel_number }}"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label class="partner-form-label" for="email">
            {{t('REDEEMABLE_EMAIL_LABEL')}}
          </label>
          <input
            type="text"
            pInputText
            readonly
            id="email"
            value="{{ selectedRedeemablePlace?.email }}"
          />
        </div>
        <div class="field col-12 md:col-5">
          <label class="partner-form-label" for="web">
            {{t('REDEEMABLE_WEBSITE_LABEL')}}
          </label>
          <input
            type="text"
            pInputText
            readonly
            id="web"
            value="{{ selectedRedeemablePlace?.website }}"
          />
        </div>
      </ng-container>
    </div>
    <button pButton pRipple type="button" class="p-button-rounded" [routerLink]="['/user', 'change-password']">
      {{t('PASSWORD_CHANGE_BUTTON_LABEL')}}
    </button>
  </div>
</div>

<ng-template #loader>
  <div class="centered">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

