import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IsMobileDevicePipe } from '@partneradmin/common';

@Component({
  selector: 'partneradmin-unauth-wrapper',
  templateUrl: './unauth-wrapper.component.html',
  styleUrls: ['./unauth-wrapper.component.scss'],
})
export class UnauthWrapperComponent {
  @Input() titleTranslateKey!: string | null;

  readonly isMobileOrTabletDevice = new IsMobileDevicePipe().transform(undefined);
  readonly today = new Date();
}
