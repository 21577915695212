import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { isBoolean, isNil } from '@partneradmin/typeguard';
import { mapTo, Observable } from 'rxjs';
import { LoginWithTokenAction } from './state/action/login-with-token.action';
import { ReloadPartnerInfoAction } from './state/action/reload-partner-info.action';

@Injectable({ providedIn: 'root' })
export class AuthTokenGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean> {
    const token = route.queryParamMap.get('token');

    if (isNil(token)) {
      const redirectWithoutToken = isBoolean(route.data['redirectWithoutToken'])
        ? route.data['redirectWithoutToken']
        : false;
      return redirectWithoutToken ? this.router.parseUrl('/') : true;
    }

    return this.store.dispatch([new LoginWithTokenAction(token), new ReloadPartnerInfoAction()]).pipe(mapTo(true));
  }
}
