import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';
import { UserState } from './state/user.state';
import {ActivatedRoute, Router} from '@angular/router';
import { LogoutAction } from './state/action/logout.action';
import {isNil} from "@partneradmin/typeguard";

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.store.selectSnapshot(UserState.isLoggedIn)) {
      return next.handle(
        req.clone({
          headers: req.headers.set('Authorization', `Bearer ${this.store.selectSnapshot(UserState.token)}`),
        })
      ).pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            const queryParams = this.route.snapshot.queryParams;

            this.store.dispatch(new LogoutAction()).subscribe(() => this.router.navigate(['/login'], {
              queryParams: queryParams
            }));
          }
          return throwError(error);
        })
      );
    }

    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error.status === 401) {
          this.store.dispatch(new LogoutAction()).subscribe(() => this.router.navigate(['/login']));
        }
        return throwError(error);
      })
    );
  }
}
