import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UnauthWrapperRoutingModule } from './unauth-wrapper-routing.module';
import { UnauthWrapperComponent } from './unauth-wrapper/unauth-wrapper.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { MobileDetectModule } from '@partneradmin/common';

@NgModule({
  declarations: [UnauthWrapperComponent],
  imports: [CommonModule, UnauthWrapperRoutingModule, MobileDetectModule, TranslocoModule],
  exports: [UnauthWrapperComponent],
})
export class UnauthWrapperModule {}
