import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, take } from 'rxjs';
import { UserState } from './state/user.state';

/**
 * Olyan oldalra nem lephet be amihez nem bejelentkezett user kell
 */
@Injectable({ providedIn: 'root' })
export class NotAuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(UserState.isLoggedIn).pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return this.router.createUrlTree(['/']);
        }
        return true;
      })
    );
  }
}
